<template>
  <v-app v-if="loading">
    <v-main>
      <div v-if="datashow">
        <div id="navbar">
          <component :is="selectedHeader" />
        </div>
        <div class="bg-pages">
          <router-view />
        </div>

        <div id="food">
          <component :is="selectedFooter" />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import headerCom1 from "@/components/headerCom1.vue";
import aboutUs from "@/components/aboutUs.vue";
import country1 from "@/coundtrycode.json";
import CryptoJS from "crypto-js";
import moment from "moment";

import footerCom from "@/components/footerCom.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  name: "App",

  props: {
    redirectCountry: {
      type: String,
      default: "ca", // Default country code
    },
  },
  components: {
    headerCom1,
    aboutUs,
    dataDomain: "",
    dataDomainData: "",
    SearchDomain: "",
    searchDomaintitle: "",
    domaintrue: false,
    footerCom,
  },
  data: () => ({
    headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
    footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],
    navbar: true,
    food: true,
    datashow: false,
    countryname: "",
    selectedHeader: "headerCom1",
    selectedFooter: "footerCom",
    loading: false,
    visitcountryname: "",

    countryOptions: [],
    siteContentData: "",
    languageData: "",
    getLanguages: "",
    countyIsocode: "",

    configReq: {
      service_type: "GetPortalConfigRQ",
      api_ver: "1",
      payload: {
        domain_name: "traveloplex.com",
        env: "CERT",
        language: "EN",
        token: "jsdjOJDKJ7675",
      },
    },

    configUser_Id: "",

    getEndpoint:"",
    loaderReq:{
      "payload": {
        "domain": "traveloplex.com",
        "etoken": "d9781f79c8e577d068587e0aca400e28"
      }
    }
  }),

  methods: {
    
    async getloaderPortal(){
      let hostname = window.location.hostname;

      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.loaderReq.payload.domain = hostname;
      }

      let Key = "8dsxBc9vDl4I9alHbJU7Dg4EBbr7AS0w";
      let domain_name = this.loaderReq.payload.domain;
      let current_date = moment(new Date()).format("YYYYMMDD");
      let fixed_value = 3;

      // console.log(current_date,'dasafsdfdasdasd');

      let compineskey = Key + domain_name + current_date + fixed_value.toString();
      const encryptedkey = CryptoJS.MD5(compineskey).toString();

      this.loaderReq.payload.etoken = encryptedkey

      // console.log(this.loaderReq,'loderred...2');

      await axios.post("https://otaget.nexus2.wistirna.com",this.loaderReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.data.response_type === "success"){
            this.getEndpoint = response.data.endpoint;

            if(this.getEndpoint){
              this.envPortal();
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching application:", error);
        });
    },

    
    indexHeader() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;
         this.countryOptions = getConfigData.payload.portal_configuration.country_options;

            this.siteContentData = getConfigData.payload.portal_configuration.site_content_data;
         this.languageData = getConfigData.payload.portal_configuration.language_data;


            console.log(this.languageData, 'siteContentDatasiteContentDatasiteContentData')

        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;
      }
    },

    envPortal() {
      let hostname = window.location.hostname;
      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.configReq.payload.domain_name = hostname;
      }

      console.log(this.configReq.payload.domain_name, "hshshshs....");

      console.log(this.configReq, "configReqconfigReqconfigReq");

      axios.get(`${this.getEndpoint}getportalconfig`, {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response) {
            this.loading = true;
            let configResponse = response.data;
            console.log(configResponse, "configResponseconfigResponse");
            this.dataDomainData =
              configResponse.payload.portal_configuration.geoip_location.country_domain.replace(
                "https://",
                ""
              );
            this.dataDomain =
              this.dataDomainData.charAt(0).toUpperCase() +
              this.dataDomainData.slice(1);
            this.SearchDomain = this.configReq.payload.domain_name;
            this.searchDomaintitle =
              configResponse.payload.portal_configuration.site_title;
            this.countryname =
              configResponse.payload.portal_configuration.country.name;

            this.getLanguages = configResponse.payload.portal_configuration.languages;

            this.countyIsocode = configResponse.payload.portal_configuration.country.iso_code;
            // this.handleRedirect(this.$route);

            if (this.languageData) {
              this.getlangaugeUrl();
              // this.localeLanguage();
            }

            let $dataname =
              configResponse.payload.portal_configuration.geoip_location
                .country_iso_code;
            // console.log(this.dataDomain,this.SearchDomain, 'response.data')
            country1.forEach((v) => {
              if ($dataname == "US" || $dataname == "CA") {
                if (v.code == $dataname) {
                  this.visitcountryname = v.name;
                }
              } else {
                this.visitcountryname = "Global";
              }
            });

            // console.log(t$data,this.SearchDomain,'this.dataDomain')
            if (this.dataDomainData.includes(this.SearchDomain)) {
              console.log("pppppppaduhdh");
              this.domaintrue = false;
            } else {
              this.domaintrue = true;
            }

            // Encrypt data using CryptoJS
            const secretKey = "portal_config_datas";
            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(configResponse),
              secretKey
            ).toString();

            localStorage.setItem("portalData", encryptedData);

            // localStorage.setItem("portalData", JSON.stringify(configResponse))

            if (configResponse) {
              this.datashow = true;
            }
            this.indexHeader();

            if (this.getLanguages && this.countyIsocode) {
              this.setLanguage();
              console.log('working...1')
              // this.$i18n.locale = `${this.countyIsocode}-${this.getLanguages[0].code}`
              // console.log(this.$i18n.locale, "langresponseresponse...1");
            }
            else{
              this.$i18n.locale = 'CA-en';
              console.log('working...2')
              // this.$i18n.locale = `${this.countyIsocode}-${this.getLanguages[0].code}`
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          // alert("Something went difficulties...");
          this.loading = false;
        });
    },

    setLanguage() {
      let getLang = this.getLanguages[0].code;
      let savedLanguage = `${this.countyIsocode}-${getLang}`;
      localStorage.setItem("setlang", savedLanguage);
      // let setlocalLang = localStorage.setItem("setlang", savedLanguage);
      // if(setlocalLang){
      //   this.localeLanguage();
      // }
    },
    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";


      if (storedLocale) {
        getlocalIso = storedLocale.split('-')[0];
        getlocalLang = storedLocale.split('-')[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split('-')[0];
        getsetlang = storedSetLang.split('-')[1];
      }


      if (storedSetLang && storedLocale) {
        console.log('Check...1');
        if (storedLocale == storedSetLang) {
          console.log('Check...2');

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        }
        else if (storedLocale !== storedSetLang) {
          console.log(getlocalIso, getsetIso, 'Check...3', getlocalLang, getsetlang);
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang) {

            localStorage.removeItem("defaultlang");
            console.log('Check...4');
            return storedSetLang;
            // this.i18n.locale = storedSetLang;

          }
          else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log(storedLocale, 'Check...5');

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      }
      else if (storedSetLang) {
        console.log('Check...6');

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      }
      else if (storedLocale) {
        console.log('Check...7');

        return storedLocale;
        // this.i18n.locale = storedLocale;
      }
      else {
        console.log('Check...8');

        return 'CA-en';
        // return `${this.countyIsocode}-${this.getLanguages[0].code}`;

        // this.i18n.locale = `${this.countyIsocode}-${this.getLanguages[0].code}`;
      }
    },


    getlangaugeUrl() {
      let langData = this.languageData
      // console.log(langData, 'languageDatalanguageDatalanguageData...1');
      // let getlocale = this.localeLanguage();
      // this.$i18n.locale = this.localeLanguage();
      var localLang = this.localeLanguage(); //CA.es

      console.log(localLang, "getegeteegtegete....0");

      this.$i18n.locale = localLang;
      console.log(this.$i18n.locale, "getegeteegtegete....1");


      // console.log(localLang, 'languageDatalanguageDatalanguageData...2');

      let langdataUrl = "";
      // console.log(localLang,'localLanglocalLang')
      Object.keys(langData).forEach(v => {
        console.log(v,this.countyIsocode, 'languageDatalanguageDatalanguageData...3');
        if (v == this.countyIsocode) {
          const lang = langData[v];

          Object.keys(lang).forEach(local => {
            // console.log(local, 'dasasasasdas')
            if (local == localLang) {

              langdataUrl = lang[local]
              console.log(local, localLang, langdataUrl, 'dasasasasdas...2');

              axios.get(langdataUrl, {
                headers: {
                  "Content-Type": "text/html; charset=utf-8",
                },
              })
                .then((response) => {
                  if (response) {
                    // this.aboutContent = response.data
                    console.log(response, "getegeteegtegete...2");
                    // console.log(response.data, "langresponseresponse");
                    const cleanedData = this.parseLanguageData(response.data);
                    this.setLanguageMessages(localLang, cleanedData);
                    // setInterval(() => {
                    //   location.reload();
                    // }, 500);
                    // this.dataloader = false;
                  }
                  // else {
                  //   this.$i18n.locale = `${this.countyIsocode}-${this.getLanguages[0].code}`
                  //   console.log(this.$i18n.locale, "langresponseresponse...1");
                  // }
                })
                .catch((error) => {
                  console.log(error, 'errorrLangaugeAPI.....')

                  this.$i18n.locale = 'CA-en'
                  // this.dataloader = true;
                })
            }
          })
        }
      });
    },


    parseLanguageData(data) {
      try {
        const cleanedData = data
          .replace(/export\s+default\s+/, '')
          .replace(/;\s*$/, ''); // Remove trailing semicolon if present
        return eval(`(${cleanedData})`);
      } catch (error) {
        console.error('Error processing language content:', error);
        return {};
      }
    },

    setLanguageMessages(locale, messages) {
      this.$i18n.setLocaleMessage(locale, messages);
      this.$i18n.locale = locale;

      console.log(this.$i18n.locale,'getegeteegtegete....3')
    }
  },

  watch: {
    "$i18n.locale": {
      immediate: true,
      handler: function () {
        // this.localeLanguage();
        this.getlangaugeUrl();
      },
      // deep: true,
    },

    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.getloaderPortal();

        if (newValue === "/loader") {
          this.navbar = false;
          this.food = false;
        } else {
          this.navbar = true;
          this.food = true;
        }
        // console.log(newValue, '4545');
      },
    },
  },

  // created() {
  //   this.envPortal();
  // },

  mounted(){
    this.getloaderPortal();
  }
  
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&family=Poppins:wght@300;400;500&display=swap");

/* .bg-pages {
background: rgb(230, 217, 26);
background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193));
} */

/* * {
  font-family: 'Poppins', sans-serif;
} */

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

.bg-pages {
  background: #f1f1f1;
  /* background-image:url('./assets/bmbg2.svg'); */
}

.v-main{
  background: #f1f1f1;
}

/* .bg-pages{
    background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);
  } */
/* .bg-pages { */

/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* background: rgb(230, 217, 26); */
/* background: linear-gradient(277deg, rgba(230,217,26,1) 0%, rgba(51,175,167,1) 58%, rgba(253,45,164,1) 100%); */
/* background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193)); */

/* margin-top:70px; */
/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* } */
</style>
