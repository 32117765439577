<template>
  <div style="background-color: #ffffffd9; height: 100%">
    <v-container>
      <v-form ref="form" v-model="valid1" @submit.prevent="submitform">
        <div class="row">
          <div class="col-md-8">
            <div class="text-center mt-3">
              <h3>{{$t("contactContent.heading1")}}</h3>

              <h5 class="mt-2">
                {{$t("contactContent.subHead1")}}
              </h5>
            </div>

            <div class="row" style="margin-top: 50px">
              <div class="col-md-6">
                <v-text-field
                  :label="this.$t('contactContent.formLabels.name')"
                  v-model="contactDetails.name"
                  :rules="name"
                  variant="outlined"
                ></v-text-field>
              </div>
              <div class="col-md-6">
                <v-select
                  :label="this.$t('contactContent.formLabels.natureEnquiry')"
                  :rules="Enquiry"
                  v-model="contactDetails.enquiry_type"
                  :items="[
                    'General Enquiry',
                    'Bookings',
                    'Cancellations Groups',
                    ' Packages and HotelsComplaints',
                    'Report Issue',
                  ]"
                  variant="outlined"
                ></v-select>
              </div>
            </div>

            <div class="row" style="margin-top: 10px">
              <div class="col-md-6">
                <v-text-field
                  :label="this.$t('contactContent.formLabels.phoneNumber')"
                  @keypress="filter(event)"
                  v-model="contactDetails.phone_no"
                  variant="outlined"
                  :rules="phonenumber"
                ></v-text-field>
              </div>
              <div class="col-md-6">
                <v-text-field
                  :label="this.$t('contactContent.formLabels.email')"
                  v-model="contactDetails.email"
                  variant="outlined"
                  :rules="email"
                ></v-text-field>
              </div>
            </div>

            <div class="row" style="margin-top: 10px">
              <div class="col-md-12">
                <v-text-field
                  v-model="contactDetails.booking_reference"
                  :label="this.$t('contactContent.formLabels.bookingRef')"
                  variant="outlined"
                ></v-text-field>
              </div>
            </div>
            <div class="row" style="margin-top: 10px">
              <div class="col-md-12">
                <v-textarea
                  :rules="comment"
                  v-model="contactDetails.comments"
                  :label="`${this.$t(
                    'contactContent.formLabels.comment'
                  )} / ${this.$t('contactContent.formLabels.questions')}`"
                  variant="outlined"
                ></v-textarea>
              </div>
            </div>

            <div class="row" style="margin-top: 10px">
              <div class="col-md-6">
                <div
                  class="d-flex"
                  style="
                    background-color: #213d77;
                    height: 50px;
                    justify-content: space-around;
                  "
                >
                  <div>
                    <canvas ref="myCanvas"></canvas>
                  </div>
                  <div
                    @click="generate()"
                    style="position: relative; top: 15px"
                  >
                    <v-icon color="white" style="cursor: pointer">
                      mdi-reload
                    </v-icon>
                  </div>
                </div>

                <div>
                  <v-text-field
                    :placeholder="
                      this.$t('contactContent.formLabels.enterCaptcha')
                    "
                    v-model="cacheData"
                    @keyup="keyData(cacheData)"
                    variant="outlined"
                  ></v-text-field>
                </div>
              </div>
              <div
                class="col-md-6 justify-lg-end justify-center"
                style="display: flex"
              >
                <v-btn
                  type="submit"
                  :disabled="loading"
                  width="200px"
                  height="45px"
                  rounded="5"
                  style="background: #94c20e; color: white; font-size: 18px"
                  >{{ $t("contactContent.formLabels.submit") }}</v-btn
                >
              </div>
            </div>
          </div>

          <div class="col-md-4 p-3">
            <div v-html="contactContent"></div>
          </div>
        </div>
      </v-form>
    </v-container>
  </div>
  <br />
  <div v-if="loader">
    <dataloader></dataloader>
  </div>

  <v-dialog v-model="dialog1">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{$t('errorContent.dialog3Content.text1')}}</h3>
        <h5>{{$t('errorContent.dialog3Content.text2')}}</h5>
        <h6>{{$t('errorContent.dialog4Content.text3')}} at {{ emailPortal }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="(dialog1 = false), (loader = false)" color="#162849"
            >{{$t('errorContent.buttonText.tryAgain')}}</v-btn
          >

          <v-btn to="/" color="#162849">{{$t('errorContent.buttonText.returnHome')}}</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="dialog2">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h5>
         {{$t('errorContent.dialog2Content.text3')}}
        </h5>

        <div class="d-flex justify-center mt-3">
          <v-btn @click="customer()" color="#162849">{{$t('errorContent.buttonText.okbtn')}}</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import axios from "axios";
import dataloader from "@/components/dataLoder.vue";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    dataloader,
  },
  data() {
    return {
      contactDetails: {
        request_type: "contact_form",
        token: "jdjfdjfkkk",
        name: "",
        enquiry_type: "",
        phone_no: "",
        email: "",
        booking_reference: "",
        comments: "",
      },
      valid1: false,
      cacheData: "",
      randomAlphabets: "",
      contactContent: "",
      getContactUrl: "",
      loading: true,
      aboutType: "",
      loader: false,
      dialog1: false,
      dialog2: false,
      emailPortal: "",
      name: [
        (v) => !!v || "Please enter your  Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],
      Enquiry: [(v) => !!v || "this field is required"],
      phonenumber: [
        (v) => !!v || "Phone Number is required",
        (v) => /^\+?\d{5,15}$/.test(v) || "Invalid Phone Number",
      ],
      email: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
      pnrno: [(v) => !!v || "This field is required"],
      comment: [(v) => !!v || "this field is required"],

      contactApi: "",
    };
  },

  methods: {
    translateDropdown(){
      this.listItems = [
        {
          text: this.$t("contactContent.natureOfEn.generalEnq"),
          value: "General Enquiry",
        },
        {
          text: this.$t("contactContent.natureOfEn.booking"),
          value: "Bookings",
        },
         {
          text: this.$t("contactContent.natureOfEn.cancelGroups"),
          value: "Cancellations Groups",
        },
         {
          text: this.$t("contactContent.natureOfEn.packagesHotel"),
          value: "Packages and HotelsComplaints",
        },
         {
          text: this.$t("contactContent.natureOfEn.reportIss"),
          value: "Report Issue",
        },
      ];
    },
    
    generateRandomAlphabets() {
      const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";

      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * alphabets.length);
        result += alphabets[randomIndex];
      }

      this.randomAlphabets = result;
      this.updateCanvas();
    },
    updateCanvas() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = "23px Arial";
      ctx.fillStyle = "white";
      ctx.fillText(this.randomAlphabets, 10, 40);
    },
    generate() {
      this.generateRandomAlphabets();
    },

    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    submitform() {
      console.log("pp", this.valid1);

      if (this.valid1) {
        this.loader = true;
        console.log(this.contactDetails, "contactDetailscontactDetails");
        axios
          .post(this.contactApi, this.contactDetails, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "response");
            this.loader = false;
            if (response.data.contact_form_response == "success") {
              this.dialog2 = true;
            }
          })
          .catch((error) => {
            console.log(error);
            setTimeout(() => {
              this.dialog1 = true;
            }, 500);
          });
      }
    },
  getConfig() {
      // let getConfigData = this.$store.state.data

      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)

      const getConfigData = getConfigDataFromLocalStorage();
      if (getConfigData) {
        let locale = this.getLangname;

        let getsiteContentData = getConfigData.payload.portal_configuration.site_content_data[locale];
        console.log(getConfigData.payload.portal_configuration.site_content_data,'getsiteContentDatagetsiteContentData')

        this.getContactUrl = getsiteContentData.contactus.url;

        this.aboutType = getsiteContentData.contactus.type;

        // console.log(getConfigData, 'head...about..1..')
        // console.log(this.getcontentUrl, 'head...about..2..')
         this.contactApi =
          getConfigData.payload.portal_configuration.API_endpoints.contactus;
        this.emailPortal = getConfigData.payload.portal_configuration.email;
        this.getConfigFunc();
      }
    },

    getConfigFunc() {
      axios
        .get(this.getContactUrl, {
          headers: {
            "Content-Type": this.aboutType,
          },
        })
        .then((response) => {
          this.contactContent = response.data;
          console.log(
            this.contactContent,
            "getContactUrlrgetContactUrlrgetContactUrlrab....."
          );
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
        });
    },
    customer() {
      (this.dialog2 = false),
        (this.loader = false),
        setTimeout(() => {
          location.reload();
        }, 100);
    },

    keyData($data) {
      if (this.randomAlphabets == $data.toUpperCase()) {
        this.loading = false;
      } else {
        this.loading = true;
      }
    },
    updateContent() {
            this.getLangname = this.$i18n.locale;
            console.log(this.getLangname,'getcontententUrl...1');
            this.getConfig();
        }
  },
  watch: {
    "$i18n.locale": {
      handler: function () {
        this.updateContent();
        this.translateDropdown();
        this.getConfig();
				this.getConfigFunc();
      },
      deep: true,
    },
    
    "contactDetails.booking_reference"(data) {
      this.contactDetails.booking_reference = data.toUpperCase();
    },
  },
  created() {
     console.log(this.$i18n.locale,'dfasfasfasfafrgdfthtretr')
     this.updateContent();
    // this.getConfig();
    this.getConfigFunc();
  },
  mounted() {
    this.generateRandomAlphabets();
  },
};
</script>

<style scoped>
.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border: 1px solid #bbb;
  text-align: left;
  height: 55px;
}
</style>
