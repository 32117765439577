import { createRouter, createWebHistory } from "vue-router";
import pagenot from "../components/pageNot.vue";
import flightInfo from "../components/flightInfo.vue";
import confirmBook from "../components/confirmBook.vue";
import bookingCofirm from "../components/bookingCofirm.vue";
import hello from "../components/homeComponent.vue";
import search from "../components/SearchResult.vue";
import loader from "../components/loaderComponent.vue";
import test from "@/components/testCom.vue";
import termUse from "@/components/termUse.vue";
import aboutUs from "@/components/aboutUs.vue";
import privacyPolicy from "@/components/privacyPolicy.vue";
import contactPage from "@/components/contactPage.vue";
import pageError from "@/components/pageError.vue";
import dashBoard from "@/components/dashBoard.vue";
import myBooking from "@/components/myBooking.vue";
import dataloader from "@/components/dataLoder.vue";
import newPassword from "@/components/newPassword.vue";
import bookingView from "@/components/bookingView.vue";
import login from "../components/loginCom.vue";
import register from "../components/registerCom.vue";
import viewBooking from "../components/viewBooking.vue";

const components = [
  {
    path: "/",
    component: hello,
  },
  {
    path: "/search",
    component: search,
  },

  {
    path: "/test",
    component: test,
  },
  {
    path: "/termUse", // Remove the leading slash
    component: termUse,
  },
  {
    path: "/pageNot",
    component: pagenot,
  },
  {
    path: "/dataloader",
    component: dataloader,
  },
  {
    path: "/loader",
    component: loader,
  },
  {
    path: "/flightInfo",
    component: flightInfo,
  },
  {
    path: "/confirmBook",
    component: confirmBook,
  },
  {
    path: "/bookingCofirm",
    component: bookingCofirm,
  },
  {
    path: "/aboutUs",
    component: aboutUs,
  },
  {
    path: "/page/privacy-policy",
    component: privacyPolicy,
  },
  {
    path: "/contactPage",
    component: contactPage,
  },
  {
    path: "/pageError",
    component: pageError,
  },
  {
    path: "/dashboard",
    component: dashBoard,
  },
  {
    path: "/mybooking",
    component: myBooking,
  },
  {
    path: "/newPassword",
    component: newPassword,
  },
  {
    path: "/bookingView",
    component: bookingView,
  },
  {
    path: "/login",
    component: login,
  },
  {
    path: "/register",
    component: register,
  },
  {
    path: "/viewbooking",
    component: viewBooking,
  },

  { path: "/:pathMatch(.*)*", name: "NotFound", component: pagenot },
];

// Create routes programmatically
// const dynamicRoutes = components.map(({ component, path }) => ({
//   path:  `${path}` || `/:country${path}`,  // Include :country parameter dynamically
//   component,
//   props: route => ({ country: route.params.country, id: route.params.id })  // Pass country as prop
// }));

const dynamicRoutes = components.flatMap(({ component, path }) => [
  {
    path: `/:country${path}`,
    component,
    props: (route) => ({ country: route.params.country, id: route.params.id }),
  },
  {
    path,
    component,
    props: (route) => ({ country: null, id: route.params.id }),
  },
]);

// Existing routes
const staticRoutes = [
  { path: "/", component: hello },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: pagenot },
];

// Combine static and dynamic routes
var routes = [...staticRoutes, ...dynamicRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const { redirectCountry } = to.query; // Get redirectCountry from query params
//   if (!to.params.id && redirectCountry) {
//     next({ path: `${to.path}/${redirectCountry}` });  // Redirect to the URL with country code
//     console.log(to.path,'pathpathpathpath...1');
//   } else {
//     next();
//     console.log(to.path,'pathpathpathpath...2');
//   }
// });

export default router;
